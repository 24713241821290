import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, HostBinding, ViewChild, ChangeDetectorRef, SimpleChanges, NgModule } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators, NgModel, ReactiveFormsModule } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDatepickerModule, MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormFieldAppearance, MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AngularMaterialModule } from '../../shared/angular-material/angular-material.module';

@Component({
    selector: 'date-range-input',
    templateUrl: './date-range.component.html',
    styles: [`
        :host {
            display: flex;
            min-width: 200px;

            &.show-prev-next-btn {
                min-width: 300px;
            }
        }

        :host ::ng-deep mat-form-field {
            width: 100%;
        }

        /* :host.no-hint-margin ::ng-deep .mat-form-field-wrapper {
            padding-bottom: 0px;
        }

        :host.no-hint-margin ::ng-deep .mat-form-field-underline {
            bottom: 0;
        } */

    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements OnInit
{

    /**
     * 是否顯示FormField Hint區域
     */
    @Input('no-margin') noMargin: boolean = true;

    /**
     * Input 樣式
     *
     * @type MatFormFieldAppearance
     */
    @Input() appearance: MatFormFieldAppearance = 'fill';

    /**
     * 日期起始時間
     *
     * @type Date
     */
    @Input('start') date_start: Date = new Date();

    /**
     * 日期起始日期變更事件
     *
     * @returns Date
     */
    @Output('startChange') date_start_change: EventEmitter<Date> = new EventEmitter<Date>();

    /**
     * 日期起始時間
     *
     * @type Date
     */
    @Input('end') date_end: Date = new Date();

    /**
      * 日期起始日期變更事件
      *
      * @returns Date
      */
    @Output('endChange') date_end_change: EventEmitter<Date> = new EventEmitter<Date>();

    /**
     * 日期前後移動時的天數
     *
     * @default day
     * @unit day | week | month | year
     */
    @Input('scope') scope: 'date' | 'day' | 'week' | 'month' | 'year' = 'day';

    /**
     * 日期區間天數
     *
     * @default 0 : 不做驗證
     */
    @Input('max-days') rangeMaxDays = 0;

    @HostBinding('class.no-hint-margin') get GetNoMarginClass() { return this.noMargin; }

    @Input() @HostBinding('class.show-prev-next-btn') show_prev_next_btn: boolean = false;

    @Input() disabled: boolean = false;

    /**
     * Range Form Group
     *
     * @value { start: moment, end: moment }
     */
    form: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormControl(moment(this.date_start), Validators.required),
        end: new UntypedFormControl(moment(this.date_start), Validators.required),
    }, [
        RangeMaxDaysValidator( () => this.rangeMaxDays ),
        BeforeStartValidator,
    ]);

    /**
     * Error State Matcher
     *
     * 用來讓group可以同步錯誤
     */
    _ErrorStateMatcher = new DateRangeErrorStateMatcher();

    private _subscriptions: Array<Subscription> = [
        this.form.get('start')!.valueChanges.pipe( ).subscribe( v => this.handleStartChange(v) ),
        this.form.get('end')!.valueChanges.pipe( ).subscribe( v => this.handleEndChange(v) ),
    ];

    constructor(private chgDete: ChangeDetectorRef) { }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void
    {
        if( changes.date_start && moment(changes.date_start.currentValue).format("yyyy-MM-DD") != this.form.value.start.format('yyyy-MM-DD') )
        {
            this.form.get('start')!.patchValue(moment( changes.date_start.currentValue ), { emitEvent: true, onlySelf: false });
        }
        if( changes.date_end && moment(changes.date_end.currentValue).format("yyyy-MM-DD") != this.form.value.end.format('yyyy-MM-DD') )
        {
            this.form.get('end')!.patchValue(moment( changes.date_end.currentValue ), { emitEvent: true, onlySelf: false });
        }
    }

    ngOnDestroy()
    {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * 處理日期向後延伸
     */
    handleNext(): void
    {
        this.handleChangeRangeDate(1)
    }

    /**
     * 處理日期向前延伸
     */
    handlePrev(): void
    {
        this.handleChangeRangeDate(-1)
    }

    /**
     * 處理日期變更
     * @param count 日期天數
     */
    handleChangeRangeDate(count: number)
    {
        const { start, end }: { start: moment.Moment, end: moment.Moment } = this.form.value;
        let scope: moment.unitOfTime.DurationConstructor | 'date' = this.scope;

        if( scope == 'date' ) { scope = 'day' }

        if( count > 1 || count < -1 ) { scope = "days" }

        start.add(count, scope);
        end.add(count, scope);

        this.form.patchValue({ start, end });
    }

    /**
     * 處理當起始日期變動時的Two-way binding
     *
     * @param v moment.Moment 來自form.get('start').valueChanges
     */
    handleStartChange(v: moment.Moment)
    {
        if( this.form.valid && this.form.get('start')!.valid )
        {
            this.date_start_change.emit( v.toDate() );
        }
    }

    /**
     * 處理當結束日期變動時的Two-way binding
     *
     * @param v moment.Moment 來自form.get('end').valueChanges
     */
    handleEndChange(v: moment.Moment)
    {
        if( this.form.valid && this.form.get('end')!.valid )
        {
            this.date_end_change.emit( v.toDate() );
        }
    }

}

//
const RangeMaxDaysValidator= (valueAccessor: () => number): ValidatorFn => (f): ValidationErrors | null => {
    const maxDays = valueAccessor();
    const start: moment.Moment = f.value.start;
    const end: moment.Moment = f.value.end;

    // 一天以上才判斷
    if( maxDays <= 0 ) { return null }

    // 只處理天數問題
    if( !start || !end ) { return null; }

    if( end.diff( start, 'days' ) > maxDays )
    {
        return {
            'max-days': {
                msg: 'msg.invalid_max_range_days',
                maxDays: maxDays,
            }
        }
    }

    return null;
}

const BeforeStartValidator: ValidatorFn = (f): ValidationErrors | null => {
    const start: moment.Moment = f.value.start;
    const end: moment.Moment = f.value.end;

    if( !start || !end ) { return null; }

    if( end.startOf('day').isBefore( start.startOf('day') ) )
    {
        return {
            'before-start': {
                msg: 'msg.invalid_end_date',
            }
        }
    }

    return null;
}

class DateRangeErrorStateMatcher implements ErrorStateMatcher {

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const isControlError = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        const isGroupError = !!(form && form.errors && (form.dirty || form.touched || isSubmitted));

        return isControlError || isGroupError;
    }

}

// Module
@NgModule({
    declarations: [DateRangeComponent],
    imports: [CommonModule, ReactiveFormsModule, AngularMaterialModule, TranslateModule],
    exports: [DateRangeComponent],
})
export class DateRangeModule { }
